import { AuthenticationContext , adalGetToken, withAdalLogin } from 'react-adal';

export const AzureADURLS = {
    local3000 : "http://localhost:3000",
    local44375 : "https://localhost:44375",
    staging : "https://staging.remote.acceptance.customerprojects.ericsson.net",
    production : "https://remote-acceptance-customerprojects.ericsson.net",
    uat :  "https://uat.remote.acceptance.customerprojects.ericsson.net"
};
export const adalConfig = {
    tenant: '92e84ceb-fbfd-47ab-be52-080c6b87953f',
    clientId: '3dba8cd5-0018-49dc-bb9f-a5cf530bf7e6',
    redirectUri: AzureADURLS.production,
    endpoints: {
        api: '3dba8cd5-0018-49dc-bb9f-a5cf530bf7e6',
    },
    cacheLocation: 'sessionStorage'
};


export const authContext = new AuthenticationContext(adalConfig);

export const getAuthToken = () => authContext.getCachedToken(adalConfig.clientId);
export const getApiToken = () => localStorage.getItem("id_token");
export const isLoggedIn = () => {
    var error = authContext.getLoginError()
    let token = authContext.getCachedToken(adalConfig.clientId)
    return  (!error)
};

export const getTokenAndRenew  = ()  => {
 const resource = {resourceGuiId : adalConfig.clientId}
 return adalGetToken(authContext,resource)
 
};
export const getValidToken = () => {
const idToken = localStorage.getItem("id_token");
return idToken;
}
export const getIDToken = () => {
    const idToken = localStorage.getItem("id_token");
    return idToken;
    }
export const withAdalLoginApi = ()=> withAdalLogin(authContext, adalConfig.endpoints.api);
export const SSOURL = () =>{
    var callBack = adalConfig.redirectUri
    var url = new URL("https://login.microsoftonline.com/92e84ceb-fbfd-47ab-be52-080c6b87953f/oauth2/authorize?response_type=id_token&client_id=3dba8cd5-0018-49dc-bb9f-a5cf530bf7e6&redirect_uri="+ callBack + "&nonce=3d1d9040-2547-482c-9fdd-a4e207d45097&sso_reload=true")
    return url.href
}


